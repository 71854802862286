"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.feeTypes = exports.deliveryUnitOptions = void 0;
exports.deliveryUnitOptions = [
    { label: "Minute(s)", value: "minutes" },
    { label: "Hour(s)", value: "hours" },
    { label: "Day(s)", value: "days" },
    { label: "Week(s)", value: "weeks" },
    { label: "Month(s)", value: "months" },
    { label: "Year(s)", value: "years" },
];
exports.feeTypes = [
    { label: "Delivery", value: "delivery" },
    { label: "Handling", value: "handling" },
];
