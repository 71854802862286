"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.orderCSVheaders = exports.statusOptions = exports.StatusEnum = exports.ProductStatuses = exports.ReturnedProductStatuses = exports.OrderStatuses = exports.TripStatuses = exports.ParcelStatuses = exports.SearchStatuses = void 0;
var SearchStatuses;
(function (SearchStatuses) {
    SearchStatuses["ACTIVE"] = "ACTIVE";
})(SearchStatuses = exports.SearchStatuses || (exports.SearchStatuses = {}));
var ParcelStatuses;
(function (ParcelStatuses) {
    ParcelStatuses["OPEN"] = "OPEN";
    ParcelStatuses["PACKED"] = "PACKED";
    ParcelStatuses["READY"] = "READY";
    ParcelStatuses["RETURNED"] = "RETURNED";
    ParcelStatuses["DELIVERED"] = "DELIVERED";
    ParcelStatuses["COLLECTED"] = "COLLECTED";
    ParcelStatuses["COLLECTED_BY_DRIVER"] = "COLLECTED-BY-DRIVER";
    ParcelStatuses["ABANDONED"] = "ABANDONED";
})(ParcelStatuses = exports.ParcelStatuses || (exports.ParcelStatuses = {}));
var TripStatuses;
(function (TripStatuses) {
    TripStatuses["IN_TRANSIT"] = "IN-TRANSIT";
    TripStatuses["ARRIVED"] = "ARRIVED";
    TripStatuses["CANCELLED"] = "CANCELLED";
})(TripStatuses = exports.TripStatuses || (exports.TripStatuses = {}));
var OrderStatuses;
(function (OrderStatuses) {
    OrderStatuses["PROCESSING"] = "PROCESSING";
    OrderStatuses["POS_ORDERED"] = "POS-ORDERED";
    OrderStatuses["PARTIALLY_FULFILLED"] = "PARTIALLY-FULFILLED";
    OrderStatuses["WAITING_LOAN_APPROVAL"] = "WAITING-LOAN-APPROVAL";
    OrderStatuses["FULFILLED"] = "FULFILLED";
    OrderStatuses["CANCELLED"] = "CANCELLED";
})(OrderStatuses = exports.OrderStatuses || (exports.OrderStatuses = {}));
var ReturnedProductStatuses;
(function (ReturnedProductStatuses) {
    ReturnedProductStatuses["STOCK"] = "STOCK";
    ReturnedProductStatuses["SUPPLIER"] = "SUPPLIER";
})(ReturnedProductStatuses = exports.ReturnedProductStatuses || (exports.ReturnedProductStatuses = {}));
var ProductStatuses;
(function (ProductStatuses) {
    ProductStatuses["DRAFT"] = "DRAFT";
    ProductStatuses["PACKED"] = "PACKED";
    ProductStatuses["PICKED"] = "PICKED";
    ProductStatuses["ORDERED"] = "ORDERED";
    ProductStatuses["REFUNDED"] = "REFUNDED";
    ProductStatuses["REPLACED"] = "REPLACED";
    ProductStatuses["RETURNED"] = "RETURNED";
    ProductStatuses["COLLECTED"] = "COLLECTED";
    ProductStatuses["AVAILABLE"] = "AVAILABLE";
    ProductStatuses["DELIVERED"] = "DELIVERED";
    ProductStatuses["RE_ORDERED"] = "RE-ORDERED";
    ProductStatuses["OUT_OF_STOCK"] = "OUT-OF-STOCK";
    ProductStatuses["RETURNED_TO_STOCK"] = "RETURNED-TO-STOCK";
    ProductStatuses["COLLECTION_READY"] = "COLLECTION-READY";
})(ProductStatuses = exports.ProductStatuses || (exports.ProductStatuses = {}));
exports.StatusEnum = __assign(__assign(__assign(__assign(__assign(__assign({}, SearchStatuses), OrderStatuses), TripStatuses), ParcelStatuses), ProductStatuses), ReturnedProductStatuses);
exports.statusOptions = [
    { value: exports.StatusEnum.OPEN, label: exports.StatusEnum.OPEN },
    { value: exports.StatusEnum.ORDERED, label: exports.StatusEnum.ORDERED },
    { value: exports.StatusEnum.DELIVERED, label: exports.StatusEnum.DELIVERED },
    { value: exports.StatusEnum.COLLECTED, label: exports.StatusEnum.COLLECTED },
    { value: exports.StatusEnum.PROCESSING, label: exports.StatusEnum.PROCESSING },
    { value: exports.StatusEnum.PARTIALLY_FULFILLED, label: exports.StatusEnum.PARTIALLY_FULFILLED },
    { value: exports.StatusEnum.FULFILLED, label: exports.StatusEnum.FULFILLED },
    { value: exports.StatusEnum.POS_ORDERED, label: exports.StatusEnum.POS_ORDERED },
];
exports.orderCSVheaders = [
    { label: "Store Name", key: "store_name" },
    { label: "Store ID", key: "store_id" },
    { label: "Code", key: "code" },
    { label: "Type", key: "type" },
    { label: "Order Date", key: "order_date" },
    { label: "Quantity", key: "quantity" },
    { label: "Price", key: "price" },
    { label: "IMEI", key: "product_reference_no" },
    { label: "On Promotion", key: "on_promotion" },
    { label: "Promotion Price", key: "promotion_price" },
    { label: "Product Status", key: "product_status" },
    { label: "Customer Name", key: "customer_name" },
    { label: "Customer Surname", key: "customer_surname" },
    { label: "Order Status", key: "order_status" },
    { label: "Qty Supplied", key: "qty_supplied" },
    { label: "Supply Price", key: "supply_price" },
    { label: "Title", key: "title" },
    { label: "Product ID", key: "pid" },
    { label: "SKU", key: "sku" },
];
