"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewBasketPage = exports.basketTableColumns = void 0;
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var basket_1 = require("../../../../../reducers/slices/basket/basket");
var console_1 = require("../../../../../reducers/slices/console");
var doshx_controls_web_2 = require("doshx_controls_web");
var get_basket_thunk_1 = require("../../../../../reducers/slices/basket/thunks/get_basket_thunk");
var routes_1 = require("../../../../../constants/routes");
var main_1 = require("../../../../../reducers/slices/main");
var applied_discounts_1 = require("../../orders/components/applied_discounts");
exports.basketTableColumns = [
    {
        displayKey: "image_url",
        label: "Image",
        width: "col-span-1",
    },
    {
        displayKey: "title",
        label: "Title",
        width: "col-span-1",
    },
    {
        displayKey: "sku",
        label: "SKU",
        width: "col-span-1",
    },
    {
        displayKey: "quantity",
        label: "Quantity",
        width: "col-span-1",
    },
    {
        displayKey: "price",
        label: "Price",
        width: "col-span-1",
    },
    {
        displayKey: "type",
        label: "Type",
        width: "col-span-1",
    },
    {
        displayKey: "discount",
        label: "Possible Discounts",
        width: "col-span-1",
    },
];
var ViewBasketPage = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var basketID = params.basketID, userID = params.userID;
    var _z = (0, react_1.useState)(1), currentPage = _z[0], setCurrentPage = _z[1];
    var _0 = (0, react_1.useState)(10), rowsPerPage = _0[0], setRowsPerPage = _0[1];
    var _1 = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }), isLoadingBasket = _1.isLoadingBasket, basket = _1.basket;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _2 = (0, react_1.useState)(""), orderNotes = _2[0], setOrderNotes = _2[1];
    var totalDiscount = 0;
    (0, react_1.useEffect)(function () {
        if (basket === null || basket === void 0 ? void 0 : basket._id)
            dispatch((0, console_1.setPageTitle)("Basket #".concat(basket === null || basket === void 0 ? void 0 : basket._id)));
    }, [basket === null || basket === void 0 ? void 0 : basket._id]);
    (0, react_1.useEffect)(function () {
        if (basketID && userID) {
            dispatch((0, get_basket_thunk_1.get_basket_thunk)({
                _id: basketID,
                user_id: userID,
            }));
        }
    }, [basketID, userID]);
    var _onViewProductDiscount = function (product) {
        return dispatch((0, main_1.setContentModal)({
            title: "".concat(product === null || product === void 0 ? void 0 : product.title, " Discounts"),
            content: react_1.default.createElement(applied_discounts_1.AppliedDiscounts, { product: product }),
        }));
    };
    //What is this for?
    // const assignParcelStatus = (parcel_status: string) => {
    //   switch (parcel_status) {
    //     case "created":
    //       dispatch(setCurrentParcelStep(0));
    //       break;
    //     case "shipped":
    //       dispatch(setCurrentParcelStep(1));
    //       break;
    //     case "arrived":
    //       dispatch(setCurrentParcelStep(2));
    //       break;
    //     case "failed":
    //       dispatch(setCurrentParcelStep(3));
    //       break;
    //     default:
    //       break;
    //   }
    // };
    if ((_a = basket === null || basket === void 0 ? void 0 : basket.product_list) === null || _a === void 0 ? void 0 : _a.length) {
        totalDiscount = (_b = basket === null || basket === void 0 ? void 0 : basket.product_list) === null || _b === void 0 ? void 0 : _b.reduce(function (accumulator, product) {
            if (product.deal && product.deal.discount) {
                return accumulator + parseFloat(product.deal.discount);
            }
            else {
                return accumulator;
            }
        }, 0);
    }
    return (react_1.default.createElement("div", { className: "flex flex-col" },
        react_1.default.createElement("div", { className: "flex pb-4" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back To Baskets", size: "xsmall", startIcon: pro_light_svg_icons_1.faArrowLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                    dispatch((0, basket_1.setBasket)(undefined));
                    dispatch((0, console_1.setPageTitle)("Baskets"));
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BASKETS_ROUTE));
                } })),
        ((_c = basket === null || basket === void 0 ? void 0 : basket.product_list) === null || _c === void 0 ? void 0 : _c.length) ? (react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-4 gap-4 items-start justify-start" },
            react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4 justify-center lg:col-span-3" },
                react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Products", size: "small", textColor: "text-black", bold: true }),
                    react_1.default.createElement("div", { className: "grid grid-cols-8 gap-2 w-full mt-3" },
                        exports.basketTableColumns.map(function (_a, index) {
                            var label = _a.label, width = _a.width;
                            return (react_1.default.createElement("div", { className: "".concat(width, " flex items-center"), key: index },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: label, className: "overflow-anywhere", size: "small", textColor: "text-black", bold: true })));
                        }),
                        react_1.default.createElement("div", { className: "col-span-2" })), (_d = basket === null || basket === void 0 ? void 0 : basket.product_list) === null || _d === void 0 ? void 0 :
                    _d.map(function (product, index) {
                        return (react_1.default.createElement("div", { className: "grid grid-cols-8 w-full gap-2 mt-3 items-center", key: index }, exports.basketTableColumns.map(function (_a, i) {
                            var _b, _c, _d;
                            var displayKey = _a.displayKey, width = _a.width;
                            if (displayKey === "discount")
                                return (react_1.default.createElement("div", { className: "items-center", key: i },
                                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { textColor: "text-blue-600", label: "View Discounts", onClick: function () { return _onViewProductDiscount(product); }, size: "xsmall" })));
                            if (displayKey === "price") {
                                return (react_1.default.createElement("div", { className: "".concat(width, " break-words items-center"), key: i },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_2.Utilities.formatMoney({
                                            symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                            value: (_b = product === null || product === void 0 ? void 0 : product.price) !== null && _b !== void 0 ? _b : "0",
                                            symbolSeparation: true,
                                        })), size: "xsmall", textColor: "text-black" })));
                            }
                            if (displayKey === "type") {
                                if ((_c = product === null || product === void 0 ? void 0 : product.type) === null || _c === void 0 ? void 0 : _c.name) {
                                    return (react_1.default.createElement("div", { className: "".concat(width, " break-words items-center"), key: i },
                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_d = product === null || product === void 0 ? void 0 : product.type) === null || _d === void 0 ? void 0 : _d.name, size: "xsmall", textColor: "text-black" })));
                                }
                                else {
                                    return react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "N/A", size: "xsmall", textColor: "text-black" });
                                }
                            }
                            if (displayKey !== "image_url" && displayKey !== "type") {
                                return (react_1.default.createElement("div", { className: "".concat(width, " break-words items-center"), key: i },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: product[displayKey], size: "xsmall", textColor: "text-black" })));
                            }
                            else {
                                return (react_1.default.createElement("div", { className: "col-span-1 break-words", key: i },
                                    react_1.default.createElement("img", { src: product[displayKey], width: 50, className: "h-10 rounded-md border border-gray-200" })));
                            }
                        })));
                    })),
                react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Sub Total", size: "small", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_2.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_e = basket === null || basket === void 0 ? void 0 : basket.sub_total) !== null && _e !== void 0 ? _e : "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-black" }))),
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Tax (Vat 15% Included)", size: "small", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_2.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_f = basket === null || basket === void 0 ? void 0 : basket.vat) !== null && _f !== void 0 ? _f : "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-black" }))),
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Delivery", size: "small", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_2.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_g = basket === null || basket === void 0 ? void 0 : basket.delivery_fee) !== null && _g !== void 0 ? _g : "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-black" }))),
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Total Discount", size: "small", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_2.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_h = String(totalDiscount)) !== null && _h !== void 0 ? _h : "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-black" }))),
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Outstanding Amount", size: "small", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_2.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_j = basket === null || basket === void 0 ? void 0 : basket.outstanding_amount) !== null && _j !== void 0 ? _j : "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-black" }))),
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Total", size: "small", textColor: "text-blue-700", bold: true })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_2.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_k = basket === null || basket === void 0 ? void 0 : basket.total) !== null && _k !== void 0 ? _k : "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-blue-700", bold: true }))),
                    react_1.default.createElement(doshx_controls_web_1.DividerControl, { addPadding: false, orientation: "horizontal" }),
                    (basket === null || basket === void 0 ? void 0 : basket.status) === "CHECKED-OUT" ? (react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Paid by customer", size: "small", textColor: "text-blue-700", bold: true })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_2.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_l = basket === null || basket === void 0 ? void 0 : basket.paid_amount) !== null && _l !== void 0 ? _l : "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-blue-700", bold: true })))) : (react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Paid by customer", size: "small", textColor: "text-blue-700", bold: true })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_2.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-blue-700", bold: true })))))),
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "".concat((_m = basket === null || basket === void 0 ? void 0 : basket.type.toUpperCase()) !== null && _m !== void 0 ? _m : ""), size: "xsmall" })),
                react_1.default.createElement("div", null,
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_light_svg_icons_1.faUserCrown, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Customer", size: "small", textColor: "text-black", bold: true }))),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { textColor: "text-blue-600", label: "View Customer", onClick: function () {
                                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.CUSTOMERS_ROUTE).concat(routes_1.CUSTOMER_PROFILE_ROUTE, "/").concat(basket === null || basket === void 0 ? void 0 : basket.user_id));
                                }, size: "small" }))),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faUser, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((_p = (_o = basket === null || basket === void 0 ? void 0 : basket.user) === null || _o === void 0 ? void 0 : _o.name) !== null && _p !== void 0 ? _p : "", " ").concat((_r = (_q = basket === null || basket === void 0 ? void 0 : basket.user) === null || _q === void 0 ? void 0 : _q.surname) !== null && _r !== void 0 ? _r : ""), size: "xsmall", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faPhone, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((_t = (_s = basket === null || basket === void 0 ? void 0 : basket.user) === null || _s === void 0 ? void 0 : _s.cell_number) !== null && _t !== void 0 ? _t : ""), size: "xsmall", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faEnvelope, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((_v = (_u = basket === null || basket === void 0 ? void 0 : basket.user) === null || _u === void 0 ? void 0 : _u.email) !== null && _v !== void 0 ? _v : ""), size: "xsmall", textColor: "text-black" })))),
                react_1.default.createElement("div", null,
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_light_svg_icons_1.faCalendar, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Basket Creation Date", size: "small", textColor: "text-black", bold: true })))),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faCalendarAlt, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_2.Utilities.formatDateTime((_w = basket === null || basket === void 0 ? void 0 : basket.createdAt) !== null && _w !== void 0 ? _w : ""), size: "xsmall", textColor: "text-black" })))),
                react_1.default.createElement("div", null,
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_light_svg_icons_1.faStoreAlt, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Store Information", size: "small", textColor: "text-black", bold: true }))),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { textColor: "text-blue-600", label: "View Store", onClick: function () {
                                    var _a;
                                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BRANCHES_ROUTE).concat(routes_1.BRANCH_DETAILS_ROUTE, "/").concat((_a = basket === null || basket === void 0 ? void 0 : basket.branch) === null || _a === void 0 ? void 0 : _a._id));
                                }, size: "small" }))),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faStore, onClick: function () {
                                    var _a;
                                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BRANCHES_ROUTE).concat(routes_1.BRANCH_DETAILS_ROUTE, "/").concat((_a = basket === null || basket === void 0 ? void 0 : basket.branch) === null || _a === void 0 ? void 0 : _a._id));
                                }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((_y = (_x = basket === null || basket === void 0 ? void 0 : basket.branch) === null || _x === void 0 ? void 0 : _x.display_name) !== null && _y !== void 0 ? _y : ""), size: "xsmall", textColor: "text-black" })))),
                react_1.default.createElement("div", null,
                    react_1.default.createElement("div", { className: "" }))))) : (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "large" }))));
};
exports.ViewBasketPage = ViewBasketPage;
