"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdersTablePage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var date_fns_1 = require("date-fns");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var order_list_report_1 = require("../../../../api/calls/order/get/order_list_report");
var channelsCellComponent_1 = require("../../../../components/channelsCellComponent");
var constants_1 = require("../../../../constants/constants");
var pages_1 = require("../../../../constants/pages");
var routes_1 = require("../../../../constants/routes");
var useSearchDebounce_1 = require("../../../../hooks/useSearchDebounce");
var branch_list_thunk_1 = require("../../../../reducers/slices/branch/thunks/get/branch_list_thunk");
var console_1 = require("../../../../reducers/slices/console");
var main_1 = require("../../../../reducers/slices/main");
var orders_1 = require("../../../../reducers/slices/orders/orders");
var order_list_thunk_1 = require("../../../../reducers/slices/orders/thunks/order_list_thunk");
var client_user_selector_1 = require("../../../../selectors/client_user_selector");
var date_range_picker_1 = require("./components/date_range_picker");
var constants_2 = require("./constants");
var constants_3 = require("../../constants");
var OrdersTablePage = function () {
    var orders;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_1.useState)(undefined), selectedStatus = _a[0], setSelectedStatus = _a[1];
    var _b = (0, react_1.useState)(undefined), selectedOrderStatus = _b[0], setSelectedOrderStatus = _b[1];
    var _c = (0, react_1.useState)(undefined), selectedBranchId = _c[0], setSelectedBranchId = _c[1];
    var _d = (0, react_1.useState)(false), showFilters = _d[0], setShowFilters = _d[1];
    var _e = (0, useSearchDebounce_1.useSearchDebounce)(1100), orderSearchString = _e[0], setOrderSearchString = _e[1];
    var _f = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }), orderList = _f.orders, isLoading = _f.isLoading, pagination = _f.pagination;
    var _g = (0, react_1.useState)(__assign(__assign({}, pages_1.initialSortValues), { key: "" })), sorter = _g[0], setSorter = _g[1];
    var _h = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }), branches = _h.branches, branchPagination = _h.pagination;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _j = (0, react_redux_1.useSelector)(client_user_selector_1.isManagerSelector), isManager = _j.isManager, branchIdList = _j.managedBanches;
    var branch_id_list = branchIdList === null || branchIdList === void 0 ? void 0 : branchIdList.join(",");
    var defaultBranchQueryParams = __assign({}, (isManager && { branch_id_list: branch_id_list }));
    var defaultOrderQueryParams = __assign(__assign(__assign({ status: selectedStatus }, (orderSearchString && { search_string: orderSearchString })), (sorter.key && { sort_by: sorter.key, order_by: sorter.direction })), (isManager ? { branch_id_list: branch_id_list } : { branch_id: selectedBranchId }));
    var applyOrderModifications = function (order) {
        var _a, _b, _c;
        return __assign(__assign({}, order), { branch_name: (_b = (_a = order === null || order === void 0 ? void 0 : order.store) === null || _a === void 0 ? void 0 : _a.store_name) !== null && _b !== void 0 ? _b : (_c = order.branch) === null || _c === void 0 ? void 0 : _c.display_name, customer: "".concat(order === null || order === void 0 ? void 0 : order.name, " ").concat(order === null || order === void 0 ? void 0 : order.surname), expected_fulfilment_date: doshx_controls_web_1.Utilities.formatDateTime(order === null || order === void 0 ? void 0 : order.expected_fulfilment_date) });
    };
    var compareOrders = function (a, b) {
        var now = new Date();
        var etaDateA = new Date(a === null || a === void 0 ? void 0 : a.expected_fulfilment_date);
        var etaDateB = new Date(b === null || b === void 0 ? void 0 : b.expected_fulfilment_date);
        var orderHasCompletedA = [constants_2.OrderStatuses.FULFILLED, constants_2.OrderStatuses.CANCELLED].includes(a.status);
        var orderHasCompletedB = [constants_2.OrderStatuses.FULFILLED, constants_2.OrderStatuses.CANCELLED].includes(b.status);
        var daysDifferenceA = (0, date_fns_1.differenceInDays)(now, etaDateA);
        var daysDifferenceB = (0, date_fns_1.differenceInDays)(now, etaDateB);
        var hoursDifferenceA = (0, date_fns_1.differenceInHours)(now, etaDateA);
        var hoursDifferenceB = (0, date_fns_1.differenceInHours)(now, etaDateB);
        var isWithinDayA = hoursDifferenceA >= -24 && hoursDifferenceA <= 24;
        var isWithinDayB = hoursDifferenceB >= -24 && hoursDifferenceB <= 24;
        if (!orderHasCompletedA && orderHasCompletedB) {
            return -1; // Order A is incomplete, but Order B is complete, so Order A comes first.
        }
        else if (orderHasCompletedA && !orderHasCompletedB) {
            return 1; // Order B is incomplete, but Order A is complete, so Order B comes first.
        }
        else if (!orderHasCompletedA && !orderHasCompletedB) {
            if (!isWithinDayA && !isWithinDayB) {
                // Both orders are overdue by days, sort by the longest overdue first
                return daysDifferenceB - daysDifferenceA;
            }
            else if (isWithinDayA && isWithinDayB) {
                // Both orders are due in days, sort by the closest due date first
                return daysDifferenceA - daysDifferenceB;
            }
            else if (!isWithinDayA && isWithinDayB) {
                // Order A is overdue by days, but Order B is due in days, so Order A comes first
                return -1;
            }
            else if (isWithinDayA && !isWithinDayB) {
                // Order B is overdue by days, but Order A is due in days, so Order B comes first
                return 1;
            }
        }
    };
    var highlightRow = function (row) {
        var now = new Date();
        var etaDate = new Date(row.expected_fulfilment_date);
        var orderHasCompleted = [constants_2.OrderStatuses.FULFILLED, constants_2.OrderStatuses.CANCELLED].includes(row.status);
        var daysDifference = (0, date_fns_1.differenceInDays)(now, etaDate);
        var hoursDifference = (0, date_fns_1.differenceInHours)(now, etaDate);
        var isWithinDay = hoursDifference >= -24 && hoursDifference <= 24;
        if (!orderHasCompleted)
            if (isWithinDay) {
                return "bg-orange-400"; // Orange background for orders due or overdue within a day
            }
            else if (daysDifference > 0) {
                return "bg-red-400"; // Pink background for overdue orders
            }
            else if (daysDifference < 0) {
                return "bg-white"; // white background for due orders
            }
    };
    orders = isManager
        ? orderList.filter(function (order) { var _a; return branchIdList.includes((_a = order === null || order === void 0 ? void 0 : order.branch) === null || _a === void 0 ? void 0 : _a._id); }).map(applyOrderModifications)
        : orderList.map(applyOrderModifications);
    // orders.sort(compareOrders);
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var handleResponse = function (response) {
        if (response.success) {
            return response.content;
        }
        else {
            dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
        }
    };
    var _onExportCSV = function (_a) {
        var fromDate = _a.fromDate, toDate = _a.toDate;
        return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, (0, order_list_report_1.order_list_report)(__assign(__assign({ from_date: fromDate, to_date: toDate }, (selectedStatus && { status: selectedStatus })), (selectedBranchId && { branch_id: selectedBranchId })))];
                    case 1:
                        response = _b.sent();
                        return [2 /*return*/, handleResponse(response)];
                }
            });
        });
    };
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Orders"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (user === null || user === void 0 ? void 0 : user._id) {
            _getOrders();
            _getBranchList();
        }
    }, [user, orderSearchString]);
    var _onStatusChange = function (status) {
        if (!status)
            _clearFilters();
        setSelectedStatus(status);
        _getOrders({
            status: status,
        });
    };
    var _clearFilters = function () {
        setSelectedStatus(undefined);
        setSelectedOrderStatus(undefined);
        setSelectedBranchId(undefined);
    };
    var _getBranchList = function (params) {
        if (params === void 0) { params = {}; }
        return dispatch((0, branch_list_thunk_1.branch_list_thunk)(__assign(__assign(__assign({}, branchPagination), params), defaultBranchQueryParams)));
    };
    var _onSearchBranches = function (search_string) { return _getBranchList(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string }))); };
    var _getOrders = function (params) {
        if (params === void 0) { params = {}; }
        return dispatch((0, order_list_thunk_1.order_list_thunk)(__assign(__assign(__assign({}, pagination), defaultOrderQueryParams), params)));
    };
    var _onPageChange = function (page) {
        return _getOrders({
            page: page,
        });
    };
    var _onRowsPerPageChange = function (size) {
        return _getOrders({
            size: size,
        });
    };
    // const rowActions = [
    //   {
    //     label: "View",
    //     onClick: (order: any) => {
    //       dispatch(setSelectedOrder(order));
    //       navigate(`${CONSOLE_ROUTE}${ORDERS_ROUTE}${ORDER_ROUTE}/${order._id}`);
    //     },
    //   },
    // ];
    var _onBranchChange = function (branch) {
        setSelectedBranchId(branch === null || branch === void 0 ? void 0 : branch._id);
        _getOrders({ branch_id: branch === null || branch === void 0 ? void 0 : branch._id, showLoader: false });
    };
    var _handleExportCSV = function () {
        return dispatch((0, main_1.setContentModal)({
            title: "Pick Date Range",
            content: react_1.default.createElement(date_range_picker_1.DateRangePicker, { onExportCSV: _onExportCSV, csvHeaders: constants_2.orderCSVheaders, filename: "orders" }),
        }));
    };
    var onSortTable = function (sort) {
        setSorter(sort);
        _getOrders({ sort_by: sort.key, order_by: sort.direction });
    };
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex justify-between pb-4" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faSlidersSimple, size: "small", backgroundColor: "bg-primary", iconColor: "text-white", onClick: function () { return setShowFilters(!showFilters); } }),
                showFilters && (react_1.default.createElement("div", { className: "flex justify-start gap-4 px-4" },
                    react_1.default.createElement("div", { className: "flex flex-row bg-white gap-4 rounded-lg p-2" },
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "All Orders", value: selectedStatus === undefined, onChange: function () { return _onStatusChange(undefined); }, size: "small", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "pl-2" },
                            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Active Orders", value: selectedStatus === constants_2.StatusEnum.ACTIVE, onChange: function () { return _onStatusChange(constants_2.StatusEnum.ACTIVE); }, size: "small", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "pl-2" },
                            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Complete Orders", value: selectedStatus === constants_2.StatusEnum.FULFILLED, onChange: function () { return _onStatusChange(constants_2.StatusEnum.FULFILLED); }, size: "small", textColor: "text-black" }))),
                    react_1.default.createElement("div", { className: "w-32 bg-white rounded-lg p-2" },
                        react_1.default.createElement(doshx_controls_web_1.NewDropdownControl, { label: "Status", options: constants_2.statusOptions, colors: {
                                textColor: "text-black",
                                labelColor: "text-black",
                                borderColor: "border-borders",
                                iconColor: "text-primary",
                            }, renderDisplay: function (item) {
                                return react_1.default.createElement(react_1.default.Fragment, null, item.label);
                            }, size: "small", value: selectedOrderStatus, errorText: "", onChange: function (item) {
                                setSelectedOrderStatus(item);
                                _getOrders({
                                    status: item === null || item === void 0 ? void 0 : item.value,
                                });
                            } })),
                    react_1.default.createElement("div", { className: "w-32 bg-white rounded-lg p-2" },
                        react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { label: "Branch", size: "small", renderDisplay: function (item) { return item.display_name; }, options: branches, value: branches === null || branches === void 0 ? void 0 : branches.find(function (_) { return _._id === selectedBranchId; }), onSearchChanged: _onSearchBranches, onChange: _onBranchChange, colors: {
                                borderColor: "border-borders",
                            } })),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Export CSV", textColor: "text-white", backgroundColor: "bg-secondary", size: "small", onClick: _handleExportCSV }))),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Search", endIcon: pro_light_svg_icons_1.faSearch, size: "small", onChange: setOrderSearchString, borderColor: "border-borders", backgroundColor: "bg-white" }))),
        isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge" })) : (react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(doshx_controls_web_1.TableControl, { key: pagination.size, sort: {
                    value: sorter,
                    onChange: onSortTable,
                }, columns: [
                    {
                        displayKey: "customer",
                        label: "Customer",
                    },
                    {
                        displayKey: "code",
                        label: "Code",
                    },
                    {
                        displayKey: "order_date",
                        label: "Date",
                    },
                    {
                        displayKey: "total",
                        label: "Amount",
                        formatter: function (_) {
                            return (_ === null || _ === void 0 ? void 0 : _.total)
                                ? doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: _.total.toString(),
                                    symbolSeparation: true,
                                })
                                : "n/a";
                        },
                    },
                    {
                        displayKey: "branch_name",
                        label: "Branch",
                    },
                    {
                        displayKey: "method",
                        label: "Method",
                    },
                    {
                        displayKey: "channel",
                        label: "Channel",
                        formatter: function (channel) {
                            return (0, channelsCellComponent_1.ChannelsCellComponent)({ channels: [channel] });
                        },
                    },
                    {
                        displayKey: "expected_fulfilment_date",
                        label: "ETA",
                    },
                    {
                        displayKey: "status",
                        label: "Status",
                        formatter: function (_) {
                            if (_) {
                                return (0, constants_3.TABLE_STATUSES)(_.status);
                            }
                        },
                    },
                ], 
                // rowActions={rowActions}
                onRowClicked: function (order) {
                    dispatch((0, orders_1.setSelectedOrder)(order));
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.ORDERS_ROUTE).concat(routes_1.ORDER_ROUTE, "/").concat(order._id));
                }, highlightRow: highlightRow, pagination: {
                    rowsPerPageOptions: [10, 15, 20],
                    currentPage: pagination.page,
                    rowsPerPage: pagination.size,
                    totalRecords: pagination.countTotal,
                    onPageChange: _onPageChange,
                    onRowsPerPageChange: _onRowsPerPageChange,
                }, data: orders, hasShadow: true, colors: pages_1.tableColorConfig })))));
};
exports.OrdersTablePage = OrdersTablePage;
