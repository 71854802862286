"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var client_branches_1 = require("../../../../../api/calls/branch/get/client_branches");
var get_client_user_1 = require("../../../../../api/calls/client/get_client_user");
var constants_1 = require("../../../../../constants/constants");
var pages_1 = require("../../../../../constants/pages");
var validateForm_1 = require("../../../../../helpers/validateForm/validateForm");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var branch_list_thunk_1 = require("../../../../../reducers/slices/branch/thunks/get/branch_list_thunk");
var client_1 = require("../../../../../reducers/slices/client/client");
var create_client_user_thunk_1 = require("../../../../../reducers/slices/client/thunks/create_client_user_thunk");
var remove_client_user_thunk_1 = require("../../../../../reducers/slices/client/thunks/remove_client_user_thunk");
var update_client_user_thunk_1 = require("../../../../../reducers/slices/client/thunks/update_client_user_thunk");
var console_1 = require("../../../../../reducers/slices/console");
var main_1 = require("../../../../../reducers/slices/main");
var constants_2 = require("./constants");
var branchTableColumns = [
    {
        displayKey: "display_name",
        label: "Branch Name",
    }
];
var UserPage = function () {
    var _a;
    var userId = (0, react_router_dom_1.useParams)().userId;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }), clientUsers = _b.clientUsers, selectedClientUser = _b.selectedClientUser, isLoading = _b.isLoading;
    var _c = (0, react_1.useState)([]), branchList = _c[0], setBranchList = _c[1];
    var branches = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).branches;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _d = (0, validateForm_1.validateForm)(selectedClientUser, { validationConfig: constants_2.validationConfig, initialValues: constants_2.initialUserValues }), errors = _d.errors, isValid = _d.isValid;
    var _e = (0, useSearchDebounce_1.useSearchDebounce)(), searchBranch = _e[0], setSearchBranch = _e[1];
    var userPermissions = (_a = selectedClientUser === null || selectedClientUser === void 0 ? void 0 : selectedClientUser.user_permissions) !== null && _a !== void 0 ? _a : (0, constants_2.initialPermissionsValues)();
    var isManagerSelected = selectedClientUser.role === "AREA_MANAGER" || selectedClientUser.role === "BRANCH_MANAGER";
    var branchOptions = __spreadArray([], branches.reduce(function (acc, branch) {
        var existingBranch = branchList.find(function (item) { return item._id === branch._id; });
        if (!existingBranch) {
            acc.push(__assign({}, branch));
        }
        return acc;
    }, []), true);
    var branchManagerOf = branches.filter(function (branch) {
        var _a;
        return (_a = selectedClientUser === null || selectedClientUser === void 0 ? void 0 : selectedClientUser.manager_of) === null || _a === void 0 ? void 0 : _a.includes(branch === null || branch === void 0 ? void 0 : branch._id);
    });
    (0, react_1.useEffect)(function () {
        _onSearchBranches(searchBranch);
    }, [searchBranch]);
    (0, react_1.useEffect)(function () {
        if (userId && clientUsers)
            _getUser();
    }, [clientUsers, userId]);
    var _getUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var user, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    user = clientUsers.find(function (user) { return user.user_id === userId; });
                    if (!!user) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, get_client_user_1.get_client_user)({ _id: userId })];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        user = response.content;
                    }
                    _a.label = 2;
                case 2:
                    dispatch((0, client_1.setSelectedClientUser)(user));
                    return [2 /*return*/];
            }
        });
    }); };
    var _deleteUser = function (query) { return __awaiter(void 0, void 0, void 0, function () {
        var payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, remove_client_user_thunk_1.remove_client_user_thunk)())];
                case 1:
                    payload = (_a.sent()).payload;
                    return [4 /*yield*/, dispatch((0, main_1.setContentModal)(null))];
                case 2:
                    _a.sent();
                    return [2 /*return*/, payload.success && navigate(-1)];
            }
        });
    }); };
    var _onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload_1, payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(selectedClientUser === null || selectedClientUser === void 0 ? void 0 : selectedClientUser.user_id)) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch((0, update_client_user_thunk_1.update_client_user_thunk)())];
                case 1:
                    payload_1 = (_a.sent()).payload;
                    return [2 /*return*/, payload_1.success && navigate(-1)];
                case 2: return [4 /*yield*/, dispatch((0, create_client_user_thunk_1.create_client_user_thunk)())];
                case 3:
                    payload = (_a.sent()).payload;
                    return [2 /*return*/, payload.success && navigate(-1)];
            }
        });
    }); };
    var handleCheckboxChange = function (feature, action) { return function (isChecked) {
        var _a, _b;
        _setUser({
            user_permissions: __assign(__assign({}, selectedClientUser.user_permissions), (_a = {}, _a[feature] = __assign(__assign({}, selectedClientUser.user_permissions[feature]), (_b = {}, _b[action] = isChecked, _b)), _a)),
        });
    }; };
    var _setUser = function (value) {
        dispatch((0, client_1.setSelectedClientUser)(__assign(__assign({}, selectedClientUser), value)));
    };
    var _onDeleteUser = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Confirm",
            content: (react_1.default.createElement("div", { className: "flex pt-4 gap-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to delete this user?", textColor: "text-inputText" }),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: _deleteUser }))),
        }));
    };
    var _onBranchChange = function (branch) {
        _setUser({ manager_of: __spreadArray(__spreadArray([], selectedClientUser === null || selectedClientUser === void 0 ? void 0 : selectedClientUser.manager_of, true), [branch._id], false) });
    };
    var _getBranches = function (params) {
        if (params === void 0) { params = {}; }
        return dispatch((0, branch_list_thunk_1.branch_list_thunk)(__assign(__assign({}, pages_1.initialPaginationValues), params)));
    };
    var _onSearchBranches = function (search_string) { return _getBranches(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string }))); };
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("".concat(selectedClientUser.user_id ? "Edit" : "Add", " User")));
    }, [selectedClientUser.user_id]);
    (0, react_1.useEffect)(function () {
        if (user === null || user === void 0 ? void 0 : user._id)
            _getBranches();
    }, [user]);
    var fetchBranchList = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, client_branches_1.get_client_branches)({ branch_id_list: selectedClientUser.manager_of.join(","), })];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        setBranchList(response.content.branch_list);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        var _a;
        if ((_a = selectedClientUser.manager_of) === null || _a === void 0 ? void 0 : _a.length) {
            fetchBranchList();
        }
    }, [selectedClientUser]);
    var _removeBranch = function (_id) {
        setBranchList(branchList.filter(function (branch) { return branch._id !== _id; }));
        _setUser({ manager_of: selectedClientUser === null || selectedClientUser === void 0 ? void 0 : selectedClientUser.manager_of.filter(function (branchId) { return branchId !== _id; }) });
    };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", addPadding: false, className: "w-full flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex flex-row" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back to Users", size: "xsmall", startIcon: pro_light_svg_icons_1.faChevronLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () { return navigate(-1); } })),
        isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge" })) : (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "w-10/12" },
            react_1.default.createElement("div", { className: "grid grid-cols-2 gap-4" },
                constants_2.tableColumns.slice(0, 4).map(function (column) { return (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { key: column.displayKey, label: column.label, required: true, size: "medium", value: selectedClientUser[column.displayKey], onChange: function (value) {
                        var _a;
                        return _setUser((_a = {}, _a[column.displayKey] = value, _a));
                    }, iconColor: "text-error", errorText: errors[column.displayKey] })); }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "User name", required: true, size: "medium", value: selectedClientUser.user_name, onChange: function (user_name) { return _setUser({ user_name: user_name }); }, iconColor: "text-error", errorText: errors.user_name }),
                !(selectedClientUser === null || selectedClientUser === void 0 ? void 0 : selectedClientUser.user_id) && (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Password", required: true, type: "password", size: "medium", value: selectedClientUser.password, onChange: function (password) { return _setUser({ password: password }); }, iconColor: "text-error", errorText: errors.password })),
                user.role === constants_1.ADMIN && (react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Account Type", required: true, options: constants_2.accountTypeOptions, iconColor: "text-primary", errorText: errors.role, value: selectedClientUser.role, onChange: function (role) { return _setUser({ role: role, manager_of: [], user_permissions: (0, constants_2.initialPermissionsValues)(role) }); } })),
                user.role === constants_1.ADMIN && (react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Status", required: true, options: constants_2.statusOptions, iconColor: "text-primary", value: selectedClientUser.status, onChange: function (status) { return _setUser({ status: status }); }, errorText: errors.status }))),
            react_1.default.createElement("div", { className: "justify-center my-5 w-8/12" }, user.role === constants_1.ADMIN && isManagerSelected && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Select Branches", bold: true, className: "mb-4", textColor: "text-black" }),
                react_1.default.createElement("div", { className: "flex justify-start gap-4 flex-row mt-4" },
                    selectedClientUser.role === "AREA_MANAGER" && (react_1.default.createElement("div", { className: "flex flex-col mb-2" },
                        react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { label: "Area manager of", options: branchOptions, onSearchChanged: function (query) {
                                setSearchBranch(query);
                            }, renderDisplay: function (_) { return "".concat(_.display_name); }, value: "", onChange: function (branch) {
                                setBranchList(__spreadArray(__spreadArray([], branchList, true), [branch], false));
                                _onBranchChange(branch);
                            } }),
                        react_1.default.createElement("div", { className: "grid grid-cols-2 gap-2 bg-black w-full rounded p-2 my-2" }, branchTableColumns.map(function (_a, index) {
                            var label = _a.label;
                            return (react_1.default.createElement("div", { className: "col-span-1 flex items-center", key: index },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: label, className: "overflow-anywhere text-white", size: "small", textColor: "text-black", bold: true })));
                        })), branchList === null || branchList === void 0 ? void 0 :
                        branchList.map(function (branch, index) {
                            return (react_1.default.createElement("div", { className: "grid grid-cols-2 w-full gap-2 items-center pb-2", key: index },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: branch === null || branch === void 0 ? void 0 : branch.display_name, size: "xsmall", textColor: "text-black" }),
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { size: "2xsmall", icon: pro_light_svg_icons_1.faTrash, backgroundColor: "bg-red-400", iconColor: "text-white", borderColor: "border-transparent", onClick: function () { return _removeBranch(branch._id); } })));
                        }))),
                    selectedClientUser.role === "BRANCH_MANAGER" && (react_1.default.createElement("div", { className: "w-32" },
                        react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { label: "Branch Manager Of", size: "small", onSearchChanged: _onSearchBranches, renderDisplay: function (_) { return "".concat(_.display_name); }, options: branches, value: branchManagerOf[0], onChange: function (item) {
                                _setUser({ manager_of: [item._id] });
                            } }))))))))),
        user.role === constants_1.ADMIN && (react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "w-10/12" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Permissions These are the default permissions for ".concat(selectedClientUser.role, " (Applies to the branch they are assigned to)"), bold: true, className: "mb-4", textColor: "text-black" }),
                react_1.default.createElement("div", { className: "flex justify-center" },
                    react_1.default.createElement("div", { className: "" }, Object.keys((0, constants_2.initialPermissionsValues)()).map(function (feature, index) {
                        var _a, _b, _c, _d;
                        return (react_1.default.createElement("div", { key: index, className: "grid grid-cols-4 gap-6" },
                            react_1.default.createElement("div", { className: "grid grid-cols-1 gap-x-6" },
                                index == 0 && react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Permissions", bold: true, className: "text-sm", textColor: "text-black" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "text-sm item-center py-4", label: constants_2.featurePermissions[feature] })),
                            react_1.default.createElement("div", { className: "grid grid-cols-4 gap-x-8" },
                                index == 0 && (react_1.default.createElement(react_1.default.Fragment, null,
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "text-sm", label: "View" }),
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "text-sm", label: "Add" }),
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "text-sm", label: "Edit" }),
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "text-sm", label: "Delete" }))),
                                react_1.default.createElement(react_1.default.Fragment, null,
                                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { value: (_a = userPermissions[feature]) === null || _a === void 0 ? void 0 : _a.read, onChange: function (_) { return handleCheckboxChange(feature, "read")(_); } }),
                                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { value: (_b = userPermissions[feature]) === null || _b === void 0 ? void 0 : _b.create, onChange: function (_) { return handleCheckboxChange(feature, "create")(_); } }),
                                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { value: (_c = userPermissions[feature]) === null || _c === void 0 ? void 0 : _c.update, onChange: function (_) { return handleCheckboxChange(feature, "update")(_); } }),
                                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { value: (_d = userPermissions[feature]) === null || _d === void 0 ? void 0 : _d.delete, onChange: function (_) { return handleCheckboxChange(feature, "delete")(_); } })))));
                    })))))),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "gap-4" },
            react_1.default.createElement("div", { className: "flex items-center justify-between my-5" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return navigate(-1); } }),
                react_1.default.createElement("div", { className: "flex justify-between gap-6" },
                    (selectedClientUser === null || selectedClientUser === void 0 ? void 0 : selectedClientUser.user_id) && user.role === constants_1.ADMIN && (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Delete User", backgroundColor: "bg-transparent", textColor: "text-dark", onClick: _onDeleteUser })),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", enabled: isValid, loading: isLoading, onClick: _onSubmit }))))));
};
exports.UserPage = UserPage;
