"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoyaltiesTablePage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var pages_1 = require("../../../../../constants/pages");
var routes_1 = require("../../../../../constants/routes");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var console_1 = require("../../../../../reducers/slices/console");
var loyalty_1 = require("../../../../../reducers/slices/loyalty/loyalty");
var get_loyalty_list_thunk_1 = require("../../../../../reducers/slices/loyalty/thunks/get/get_loyalty_list_thunk");
var constants_1 = require("./constants");
var LoyaltiesTablePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_1.useState)(false), showFilters = _a[0], setShowFilters = _a[1];
    var _b = (0, react_1.useState)(null), fromDate = _b[0], setFromDate = _b[1];
    var _c = (0, react_1.useState)(null), cardLinked = _c[0], setCardLinked = _c[1];
    var _d = (0, react_1.useState)(null), toDate = _d[0], setToDate = _d[1];
    var _e = (0, react_1.useState)(pages_1.initialSortValues), sorter = _e[0], setSorter = _e[1];
    var _f = (0, useSearchDebounce_1.useSearchDebounce)(1100), searchString = _f[0], setSearchString = _f[1];
    var _g = (0, react_redux_1.useSelector)(function (state) { return state.loyaltyState; }), loyalties = _g.loyalties, isLoading = _g.isLoading, pagination = _g.pagination;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var defaultQueryParams = __assign({ is_card_linked: cardLinked, to_date: toDate, from_date: fromDate }, (searchString && { search_string: searchString, showLoader: false }));
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Loyalty"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (user === null || user === void 0 ? void 0 : user._id) {
            _getLoyalties({ showLoader: !searchString });
        }
    }, [user, searchString]);
    var _onCardStatusChange = function (status) {
        setCardLinked(status === "yes");
        _getLoyalties();
    };
    var _onFilterByDate = function () {
        _getLoyalties();
    };
    var _clearFilters = function () {
        setCardLinked(null);
        setFromDate(null);
        setToDate(null);
    };
    var _getLoyalties = function (params) {
        if (params === void 0) { params = {}; }
        return dispatch((0, get_loyalty_list_thunk_1.get_loyalty_list_thunk)(__assign(__assign(__assign({}, pagination), defaultQueryParams), params)));
    };
    var _onPageChange = function (page) {
        return _getLoyalties({
            page: page,
        });
    };
    var _onRowsPerPageChange = function (size) {
        return _getLoyalties({
            size: size,
        });
    };
    var rowActions = [
        {
            label: "View",
            onClick: function (loyalty) {
                dispatch((0, loyalty_1.setSelectedLoyalty)(loyalty));
                navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.CUSTOMERS_ROUTE).concat(routes_1.CUSTOMER_PROFILE_ROUTE, "/").concat(loyalty === null || loyalty === void 0 ? void 0 : loyalty.user_id));
            },
        },
    ];
    var onSortTable = function (sorter) {
        setSorter(sorter);
        _getLoyalties({ sort_by: sorter.key, order_by: sorter.direction });
    };
    return (react_1.default.createElement("div", { className: "w-full" }, isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge" })) : (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex justify-between " },
            react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faSlidersSimple, size: "small", backgroundColor: "bg-primary", iconColor: "text-white", onClick: function () { return setShowFilters(!showFilters); } }),
            showFilters && (react_1.default.createElement("div", { className: "flex flex-grow justify-start gap-4 pl-4" },
                react_1.default.createElement("div", { className: "w-32 bg-white rounded-md py-2 text-center cursor-pointer" },
                    react_1.default.createElement(doshx_controls_web_1.PopoverControl, { content: react_1.default.createElement("div", { className: "flex flex-col gap-4 bg-white border-borders rounded-md" },
                            react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "From Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: fromDate, onChange: function (v) {
                                    setFromDate(doshx_controls_web_1.Utilities.formatDateTime(v, "YYYY-MM-DD"));
                                }, size: "small" }),
                            react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "To Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: toDate, onChange: function (v) {
                                    setToDate(doshx_controls_web_1.Utilities.formatDateTime(v, "YYYY-MM-DD"));
                                }, size: "small" }),
                            react_1.default.createElement("div", { className: "flex justify-end" },
                                react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "apply", size: "xsmall", onClick: _onFilterByDate }))), behavior: "click" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Date Filter" }))),
                react_1.default.createElement("div", { className: "w-32 bg-white rounded-md" },
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Card Linked", required: true, options: [
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" },
                        ], labelColor: "text-black", textColor: "text-black", borderColor: "border-white", iconColor: "text-black", size: "small", value: cardLinked ? "yes" : "no", errorText: "", onChange: _onCardStatusChange })))),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", backgroundColor: "bg-white", label: "Search", endIcon: pro_light_svg_icons_1.faSearch, size: "small", onChange: setSearchString })),
        react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(doshx_controls_web_1.TableControl, { sort: {
                    value: sorter,
                    onChange: onSortTable,
                }, columns: constants_1.tableColumns, rowActions: rowActions, data: loyalties, hasShadow: true, pagination: {
                    currentPage: pagination.page,
                    rowsPerPage: pagination.size,
                    totalRecords: pagination.countTotal,
                    onPageChange: _onPageChange,
                    onRowsPerPageChange: _onRowsPerPageChange,
                }, colors: pages_1.tableColorConfig }))))));
};
exports.LoyaltiesTablePage = LoyaltiesTablePage;
