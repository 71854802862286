"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_deal_products_by_category_id_list_thunk = exports.get_voucher_products_thunk = exports.get_client_filtered_combo_products_thunk = exports.get_client_filtered_products_thunk = exports.get_deal_products_thunk = exports.get_products_thunk = exports.get_products_shopify_thunk = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var product_list_1 = require("../../../../api/calls/product/product_list");
var get_products_1 = require("../../../../api/calls/shopify/get_products");
var product_conversion_1 = require("../../../../conversions/product_conversion");
var main_1 = require("../../main");
var product_1 = require("../product");
var branch_product_list_1 = require("../../../../api/calls/product/branch_product_list");
var get_filtered_products_1 = require("../../../../api/calls/product/get_filtered_products");
var get_products_by_category_id_list_1 = require("../../../../api/calls/product/get_products_by_category_id_list");
exports.get_products_shopify_thunk = (0, toolkit_1.createAsyncThunk)("product/get_products_shopify_thunk", function (arg, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, response;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getState();
                    return [4 /*yield*/, (0, get_products_1.get_products)()];
                case 1:
                    response = _c.sent();
                    console.log("response ====>", response);
                    if (response.success) {
                        dispatch((0, product_1.setProducts)(product_conversion_1.productConversion.toArray((_b = response.data.content) !== null && _b !== void 0 ? _b : [], "shopify")));
                    }
                    else {
                        //  dispatch(setMessageModal({ title: "Something went wrong!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
exports.get_products_thunk = (0, toolkit_1.createAsyncThunk)("product/get_products_thunk", function (query, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var _token, countTotal, newQuery, page, size, response, state, payload;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        return __generator(this, function (_m) {
            switch (_m.label) {
                case 0:
                    _token = query === null || query === void 0 ? void 0 : query.token;
                    query === null || query === void 0 ? true : delete query.token;
                    countTotal = query.countTotal, newQuery = __rest(query, ["countTotal"]);
                    page = query.page, size = query.size;
                    state = getState();
                    (0, product_1.setLoading)(true);
                    if (!(((_c = (_b = state === null || state === void 0 ? void 0 : state.userState) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c.role) !== "ADMIN")) return [3 /*break*/, 2];
                    payload = {
                        page: page,
                        size: size,
                        client_id: (_e = (_d = state === null || state === void 0 ? void 0 : state.userState) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e.client_id,
                        branch_id_list: (_g = (_f = state === null || state === void 0 ? void 0 : state.userState) === null || _f === void 0 ? void 0 : _f.user) === null || _g === void 0 ? void 0 : _g.manager_of,
                    };
                    return [4 /*yield*/, (0, branch_product_list_1.get_branch_product_list)(payload)];
                case 1:
                    response = _m.sent();
                    (0, product_1.setLoading)(false);
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, (0, product_list_1.get_product_list)(newQuery, _token)];
                case 3:
                    response = _m.sent();
                    (0, product_1.setLoading)(false);
                    _m.label = 4;
                case 4:
                    if (response.success) {
                        if (newQuery.is_add_ons_request) {
                            dispatch((0, product_1.setProductAddOns)(product_conversion_1.productConversion.toArray((_j = (_h = response.content) === null || _h === void 0 ? void 0 : _h.product_list) !== null && _j !== void 0 ? _j : [], "LETSTRADE")));
                            dispatch((0, product_1.setProductAddOnsPagination)({
                                page: page,
                                size: size,
                                countTotal: response.content.count,
                            }));
                        }
                        else {
                            dispatch((0, product_1.setProducts)(product_conversion_1.productConversion.toArray((_l = (_k = response.content) === null || _k === void 0 ? void 0 : _k.product_list) !== null && _l !== void 0 ? _l : [], "LETSTRADE")));
                            (0, product_1.setLoading)(false);
                        }
                        dispatch((0, product_1.setTablePagination)({
                            page: page,
                            size: size,
                            countTotal: response.content.count,
                        }));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
exports.get_deal_products_thunk = (0, toolkit_1.createAsyncThunk)("product/get_deal_products_thunk", function (query, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var countTotal, newQuery, response, page, size;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    countTotal = query.countTotal, newQuery = __rest(query, ["countTotal"]);
                    (0, product_1.setLoading)(true);
                    return [4 /*yield*/, (0, product_list_1.get_product_list)(newQuery)];
                case 1:
                    response = _d.sent();
                    page = query.page, size = query.size;
                    (0, product_1.setLoading)(false);
                    if (response.success) {
                        dispatch((0, product_1.setDealProducts)(product_conversion_1.productConversion.toArray((_c = (_b = response.content) === null || _b === void 0 ? void 0 : _b.product_list) !== null && _c !== void 0 ? _c : [], "LETSTRADE")));
                        dispatch((0, product_1.setDealProductsPagination)({
                            page: page,
                            size: size,
                            countTotal: response.content.count,
                        }));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong fetching deal products!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
exports.get_client_filtered_products_thunk = (0, toolkit_1.createAsyncThunk)("product/get_client_filtered_products_thunk", function (query, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var countTotal, newQuery, response, page, size;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    countTotal = query.countTotal, newQuery = __rest(query, ["countTotal"]);
                    (0, product_1.setLoading)(true);
                    return [4 /*yield*/, (0, get_filtered_products_1.get_filtered_product_list)({
                            page: String(newQuery === null || newQuery === void 0 ? void 0 : newQuery.page),
                            size: String(newQuery === null || newQuery === void 0 ? void 0 : newQuery.size),
                            product_exception_list: (_b = newQuery === null || newQuery === void 0 ? void 0 : newQuery.product_exception_list) !== null && _b !== void 0 ? _b : [],
                        })];
                case 1:
                    response = _e.sent();
                    page = query.page, size = query.size;
                    (0, product_1.setLoading)(false);
                    if (response.success) {
                        dispatch((0, product_1.setClientFilteredProducts)(product_conversion_1.productConversion.toArray((_d = (_c = response.content) === null || _c === void 0 ? void 0 : _c.product_list) !== null && _d !== void 0 ? _d : [], "LETSTRADE")));
                        dispatch((0, product_1.setClientFilteredProductsPagination)({
                            page: page,
                            size: size,
                            countTotal: response.content.count,
                        }));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong fetching filtered products!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
exports.get_client_filtered_combo_products_thunk = (0, toolkit_1.createAsyncThunk)("product/get_client_filtered_combo_products_thunk", function (query, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var countTotal, newQuery, response, page, size;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    countTotal = query.countTotal, newQuery = __rest(query, ["countTotal"]);
                    (0, product_1.setLoading)(true);
                    return [4 /*yield*/, (0, get_filtered_products_1.get_filtered_product_list)({
                            page: String(newQuery === null || newQuery === void 0 ? void 0 : newQuery.page),
                            size: String(newQuery === null || newQuery === void 0 ? void 0 : newQuery.size),
                            product_exception_list: (_b = newQuery === null || newQuery === void 0 ? void 0 : newQuery.product_exception_list) !== null && _b !== void 0 ? _b : [],
                        })];
                case 1:
                    response = _e.sent();
                    page = query.page, size = query.size;
                    (0, product_1.setLoading)(false);
                    if (response.success) {
                        dispatch((0, product_1.setClientFilteredComboProducts)(product_conversion_1.productConversion.toArray((_d = (_c = response.content) === null || _c === void 0 ? void 0 : _c.product_list) !== null && _d !== void 0 ? _d : [], "LETSTRADE")));
                        dispatch((0, product_1.setClientFilteredComboProductsPagination)({
                            page: page,
                            size: size,
                            countTotal: response.content.count,
                        }));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong fetching filtered combo products!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
exports.get_voucher_products_thunk = (0, toolkit_1.createAsyncThunk)("product/get_voucher_products_thunk", function (query, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var countTotal, newQuery, response, page, size;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    countTotal = query.countTotal, newQuery = __rest(query, ["countTotal"]);
                    (0, product_1.setLoading)(true);
                    return [4 /*yield*/, (0, product_list_1.get_product_list)(newQuery)];
                case 1:
                    response = _d.sent();
                    page = query.page, size = query.size;
                    (0, product_1.setLoading)(false);
                    if (response.success) {
                        dispatch((0, product_1.setVoucherProducts)(product_conversion_1.productConversion.toArray((_c = (_b = response.content) === null || _b === void 0 ? void 0 : _b.product_list) !== null && _c !== void 0 ? _c : [], "LETSTRADE")));
                        dispatch((0, product_1.setVoucherProductsPagination)({
                            page: page,
                            size: size,
                            countTotal: response.content.count,
                        }));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong fetching voucher products!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
exports.get_deal_products_by_category_id_list_thunk = (0, toolkit_1.createAsyncThunk)("product/get_deal_products_thunk", function (query, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var countTotal, newQuery, response, page, size;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    countTotal = query.countTotal, newQuery = __rest(query, ["countTotal"]);
                    (0, product_1.setLoading)(true);
                    return [4 /*yield*/, (0, get_products_by_category_id_list_1.get_products_by_category_id_list)(newQuery)];
                case 1:
                    response = _d.sent();
                    page = query.page, size = query.size;
                    (0, product_1.setLoading)(false);
                    if (response.success) {
                        dispatch((0, product_1.setDealCategoryProducts)(product_conversion_1.productConversion.toArray((_c = (_b = response.content) === null || _b === void 0 ? void 0 : _b.product_list) !== null && _c !== void 0 ? _c : [], "LETSTRADE")));
                        dispatch((0, product_1.setDealCategoryProductsPagination)({
                            page: page,
                            size: size,
                            countTotal: response.content.count,
                        }));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong fetching products by category list!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
