"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.productConversion = void 0;
var ProductConversion = /** @class */ (function () {
    function ProductConversion() {
    }
    ProductConversion.prototype.shopify = function (product) {
        var _product = {
            _id: product.product_id,
            client_id: "",
            image_url: "",
            image_list: undefined,
            title: product.title,
            sub_title: "",
            description: "",
            brand: "",
            manufacturer: "",
            sku: product.price,
            pid: "",
            quantity_type: "",
            quantity: product.quantity,
            cost_price: "",
            price: product.price,
            recommended: false,
            on_promotion: false,
            promotion_price: "",
            estimated_delivery_time: "",
            estimated_collection_time: "",
            actual_delivery_time: "",
            actual_collection_time: "",
            variations: undefined,
            category: undefined,
            status: "",
            type: undefined,
            barcode: product.weight,
            notes: "",
            extra_fields: undefined,
            add_ons: undefined,
            deleted: false,
            rating: undefined,
            deletedAt: "",
            tags: undefined,
            channels: [],
            vatable: false,
            track_quantity: false,
            notify_when_stock_low: false,
            continue_selling_when_out_of_stock: false,
        };
        return _product;
    };
    ProductConversion.prototype.letstrade = function (product) {
        var _product = __assign(__assign({}, product), { channel: "LETSTRADE" });
        return _product;
    };
    ProductConversion.prototype.toArray = function (data, type) {
        var _this = this;
        return data.map(function (x) {
            switch (type) {
                case "LETSTRADE":
                    return _this.letstrade(x);
                case "shopify":
                    return _this.shopify(x);
                default:
                    return _this.letstrade(x);
            }
        });
    };
    return ProductConversion;
}());
exports.productConversion = new ProductConversion();
